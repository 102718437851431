$cookies-eu-background-color: #aaaaaa;
$cookies-eu-button-background-color: #ffffff;
$cookies-eu-button-color: #000000;
$cookies-eu-mobile: 'max-width: 767px';

.cookies-eu {
  line-height: 1.5em;
  font-size: 16px;

  position: fixed;
  z-index: 999;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 1em 1.8em;

  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: row;
  flex-direction: row;

  background: $cookies-eu-background-color;

  @media ($cookies-eu-mobile) {
    font-size: 16px;
    line-height: 1.5em;

    -ms-flex-align: unset;
    align-items: unset;
    -ms-flex-direction: column;
    flex-direction: column;
    // position: initial;
  }

  .cookies-eu-content-holder {
    color: #ffffff;
    -webkit-flex: 1; /* Safari 6.1+ */
    -ms-flex: 1; /* IE 10 */
    flex: 1;
    padding-right: 20px;
    
    .cookies-eu-link {
      color: #e8e8e8;
      text-decoration: underline;
      display: inline-block;
      &:hover {
        color: #ffffff;
        text-decoration: underline;
      }
      &:active {
        color: #e8e8e8;
        text-decoration: none;
      }
    }
    
    @media ($cookies-eu-mobile) {
      padding-right: 0;
      margin-bottom: 1em;
    }
  }

  .cookies-eu-button-holder {
    display: flex;

    .cookies-eu-ok {
      display: block;
      padding: .75em .8em;
      font-family: Helvetica,Calibri,Arial,sans-serif;
      font-weight: 700;
      text-align: center;
      white-space: nowrap;
      border: 0;
      min-width: 160px;
      font-size: .9em;
      cursor: pointer;
      color: $cookies-eu-button-color;
      outline: none;
      background-color: $cookies-eu-button-background-color;
      margin: 0;
      -ms-flex: 1; /* IE 10 */
     -webkit-flex: 1; /* Safari 6.1+ */
      flex: 1;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
