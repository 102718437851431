@mixin bp-3-columns-without-margin {
  @media only screen and (max-width: 960px) {
    @content;
  }
}
@mixin bp-2-columns {
  @media only screen and (max-width: 800px) {
    @content;
  }
}
@mixin bp-1-column {
  @media only screen and (max-width: 600px) {
    @content;
  }
}