/** Global style settings **/
  
@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), 
    font-url('raleway-webfont.woff2') format('woff2'), 
    font-url('fonts/raleway-webfont.woff') format('woff'),
    font-url('fonts/raleway-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  background: #555555;
  overflow-x: hidden;
  @include font-size(1.2 * $fontSize);
  font-family: Raleway, Arial, Helvetica, sans-serif;
  color: #000000;
  position: relative;
  @include bp-2-columns {
    word-wrap: break-all;
    overflow-wrap: break-word;
  }
  @include bp-1-column {
    word-wrap: break-all;
    overflow-wrap: break-word;
  }
  p {
    text-align: justify;
    padding-bottom: 16px;
  }
  article {
    line-height: 24px;
    padding-bottom: 30px;
    img {
      max-height: 400px;
      margin: 0 auto;
      display: block;
      box-shadow: 1px 1px 4px #888888;
    }
    ol {
      list-style: decimal outside;
      margin-left: 18px;
      padding-bottom: 16px;
    }
    ul {
      list-style: square outside;
      margin-left: 18px;
      padding-bottom: 16px;
    }
  }
}
header {
  background: $headerBackground;
  .header-logo {
    padding:9px 0;
    float: left;
    img {
      width: 100%;
    }
  }
  #mobile-nav-opener {
    float: right;
    //display: none;
    width: 28px;
    cursor: pointer;
    margin: 12px 10px 0 0;
    padding: 5px 3px 0 3px;
    @include border-radius(1px);
    @include bp-3-columns-without-margin {
      display: block;
    }
    @include bp-2-columns {
      display: block;
    }
    @include bp-1-column {
      display: block;
    }
    p {
      border-top: 2px solid #ffffff;
      padding:3px;
    }
    &:hover {
      background: #ffffff;
      p {
        border-color: #000000;
      }
    }
    &:active {
      background: #dddddd;
    }
  }
  nav {
    ul {
      &#nav-list {
        font-weight: normal;
        margin: 11px 0;
        display: none;
        @include bp-3-columns-without-margin {
          display: none;
          padding-bottom: 0.5 * $fontSize;
          text-align: center;
        }
        @include bp-2-columns {
          display: none;
          padding-bottom: 0.5 * $fontSize;
          text-align: center;
        }
        @include bp-1-column {
          display: none;
          padding-bottom: 0.5 * $fontSize;
          text-align: center;
        }
        li {
          margin-left: 10px;
          margin-left: calculateRem(10px);
          font-size: 18px;
          padding: 8px;
          text-align: center;
          a {
            color: #ffffff;
            padding: 4px;
            &:hover {
              background: #ffffff;
              color: #000000;
              text-decoration: none;
            }
            &:active {
              color: #888888;
            }
          }
        }
      }
    }
  }
}
header::before,
header::after {
  content: " ";
  display: table;
}
header::after {
  clear: both;
}
main {
  background: #ffffff;
  overflow: hidden; // Required to avoid gap between main and header due to margin-top of h1 (cf. wishlist/new when this setting is off)
}
a {
  color: #3c9dff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: none;
    color: #bbbbbb;
  }
}
h1 {
  font-weight: normal;
  text-align: center;
  line-height: normal;
  @include font-size(3 * $fontSize);
  margin-top: 3 * $fontSize;
  margin-bottom: 3 * $fontSize;
  @include bp-2-columns {
    @include font-size(2 * $fontSize);
  }
  @include bp-1-column {
    @include font-size(2 * $fontSize);
  }
}
h2 {
  font-weight: normal;
  text-align: center;
  @include font-size(2 * $fontSize);
  margin-top: 2 * $fontSize;
  margin-bottom: 2 * $fontSize;
  @include bp-2-columns {
    @include font-size(1.6 * $fontSize);
  }
  @include bp-1-column {
    @include font-size(1.6 * $fontSize);
  }
}
h3 {
  text-align: center;
}
h4 {
  text-align: center;
  @include font-size(1.4 * $fontSize);
}
p {
  overflow: hidden;
}
ol, ul {
  text-align: left;
  .odd {
    background: #eeeeee;
    padding: 0.5 * $fontSize;
  }
  .even {
    padding: 0.5 * $fontSize;
  }
  .align-left {
    text-align: left;
  }
}
input[type="submit"] {
  @include font-size(1 * $fontSize);
}
.button, input[type="submit"] {
  background: #000000;
  //@include border-radius(50px);
  border: 1px solid transparent;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  padding: (0.4 * $fontSize) (0.5 * $fontSize);
  padding: calculateRem(0.4 * $fontSize) calculateRem(0.5 * $fontSize);
  white-space: nowrap;
}
.button, input[type="submit"] {
  &:hover {
    background: #333333;
    text-decoration: none; 
  }
  &:active {
    background: #888888;
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:focus {
    border: 1px solid #3c9dff;
    text-decoration: none;
  }
}
form {
  text-align: center;
  p {
    text-align: center;
  }
  input, textarea, select {
    width: 80%;
    max-width: 600px;
    border: 1px solid #dddddd;
    margin-bottom: 2 * $fontSize;
    margin-bottom: calculateRem(2 * $fontSize);
    padding: 0.5 * $fontSize;
    padding: calculateRem(0.5 * $fontSize);
    box-sizing: border-box;
    font-family: Raleway, Arial, Helvetica, sans-serif;
    @include font-size($fontSize);
    &:focus {
      border: 1px solid #444444;
    }
  }
  input[type="checkbox"], input[type="radio"] {
    width: auto;
    margin: 0;
  }
  .acceptance {
    input[type="checkbox"], input[type="radio"] {
      margin-top: 1 * $fontSize;
      margin-top: calculateRem(1 * $fontSize);
    }
  }
  label {
    vertical-align: bottom;
  }
  .label {
    margin-bottom: 0.25 * $fontSize;
    margin-bottom: calculateRem(0.25 * $fontSize);
    label {
      font-weight: bold;
    }
  }
  // Radio buttons itself have no margins so the surrounding paragraphs must compensate this
  p.radio-buttons {
    margin-bottom: 2 * $fontSize;
    margin-bottom: calculateRem(2 * $fontSize);
  }
  // Handling of dynamic addition of new links to an item
  .link_fields {
    position: relative;
  }
  .add_item_link {
    @extend input;
    background: #ffffff;
    display: inline-block;
    border-style: dashed;
  }
  .item-link-field { // One of those dynamically added link fields (or the very first, fixed, link field, respectively)
    margin-bottom: 0.5 * $fontSize;
    margin-bottom: calculateRem(0.5 * $fontSize);
  }
  .remove-item-link {
    position: absolute;
    margin-left: 5px;
    top: 8px;
  }
  // end
  .no-margin {
    margin: 0;
  }
  .acceptance {
    width: 90%;
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2 * $fontSize;
    margin-bottom: calculateRem(2 * $fontSize);
  }
  .image-restrictions {
    color: #999999;
    @include font-size(0.85714285714285714285714285714286 * $fontSize);
  }
  .g-recaptcha {
    div {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2 * $fontSize;
      margin-bottom: calculateRem(2 * $fontSize);
    }
  }
}
button {
  margin-bottom: 2 * $fontSize;
  margin-bottom: calculateRem(2 * $fontSize);
}
.tooltip-button {
  cursor: pointer;
  vertical-align: bottom;
  img {
    vertical-align: middle;
  }
}
.tooltip {
  background: #cccccc;
  @include font-size(12px);
  display: block;
  padding: 0.2 * $fontSize;
  padding: calculateRem(0.2 * $fontSize);
  max-width: 600px;
  width: 80%;
  margin: (0.5 * $fontSize) auto;
  margin: (calculateRem(0.5 * $fontSize)) auto;
  box-sizing: border-box;
}
.highlight {
  background: #eeeeee;
  padding: (1.5 * $fontSize) (0.5 * $fontSize);
  text-align: center;
  margin-bottom: 2 * $fontSize;
  margin-bottom: calculateRem(2 * $fontSize);
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  p {
    text-align: center;
  }
  .wrapper {
    color: #999999;
    max-width: 400px;
    text-align: left;
    margin: 0 auto;
    h4 {
      text-align: left;
      padding-bottom: $fontSize;
    }
    p {
      padding-bottom: 0.5 * $fontSize;
      text-align: left;
    }
    ul {
      list-style-position: outside;
      list-style: square;
      padding-left: 14px;
      li {
        padding-bottom: 0.2 * $fontSize;
      }
    }
  }
}
.back-to-wishlist {
  background: #eeeeee;
  @include font-size(24px);
  padding: 2 * $fontSize;
  a {
    color: #000000;
  }
}
#errorExplanation{
  text-align: center;
  //border: 2px dashed #e20000;
  background: #ff9f9f;
  max-width: 600px;
  width: 80%;
  margin: 0 auto;
  padding: 0.5 * $fontSize;
  margin-bottom: $fontSize; 
  .title{
    @include font-size(24px);
    margin-bottom: 0.5 * $fontSize;
  }
  ul{
    list-style: square outside none;
    padding-left: $fontSize;
    text-align: left;
    li {
      margin-bottom: 0.5 * $fontSize;
    }  
  }
}
footer {
  padding: (4 * $fontSize) 0;
  text-align: left;
  @include font-size(0.95 * $fontSize);
  ul li {
    padding: (0.4 * $fontSize) 0;
    text-align: center;
  }
  b {
    color: #888888;
    padding-left: 0.4 * $fontSize;
  }
  a {
    color: #ffffff;
    text-decoration: none;
    padding: 0.4 * $fontSize;
    &:hover {
      color: #000000;
      background-color: #ffffff;
      text-decoration: none;
    }
    &:active {
      background-color: #aaaaaa;
      text-decoration: none;
    }
  }
}
//**** For development: Missing translation 
.translation_missing {
  background: #ff1f88;
}