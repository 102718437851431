/** Base settings **/
@import 'base/_variables';
@import 'base/_normalize';
@import 'base/_mixins';

/** Framework settings **/
@import 'framework/_breakpoints';
@import 'framework/_grid';

/** Global settings **/
@import "global";

/** Page and/or specific styles **/
@import "pages";

/** Cookies consent banner **/
@import "cookies_eu_custom";