@function calculateRem($size) {
  $remSize: $size / 16px; //$fontSize;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
   /*@include bp-2-columns {
    font-size: 0.85714285714285714285714285714286 * $size;
    font-size: calculateRem(0.85714285714285714285714285714286 * $size);
  }
  @include bp-1-column {
    font-size: 0.85714285714285714285714285714286 * $size;
    font-size: calculateRem(0.85714285714285714285714285714286 * $size);
  }*/
}

@mixin line-height($size) {
  line-height: $size;
  line-height: calculateRem($size); 
}
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}