#language-selection {
  height: 300px;
  p {
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    img {
      padding-right: 8px;
      vertical-align: middle;
    }
  }
}
#post-list {
  h2 {
    text-align: left;
    margin: 14px 0;
  }
  .post-img {
    float: left;
    width: 40%;
    max-width: 300px;
    margin-right: 20px;
    margin-bottom: 14px;
    box-shadow: 1px 1px 4px #888888;
  }
  .post {
    border-bottom: 1px solid #dddddd;
    .post-meta {
      @include font-size(0.85 * $fontSize);
      color: #888888;
      padding-bottom: 14px;
    }
  }
}
