/*** Grid settings ***/

// Set grid variables

// variables for 3 columns
$totalColumns: 3;
$gutterWidth: 3.125;
$singleColumnWidth: ((100 - ($gutterWidth) * ($totalColumns - 1)) * 1%) / $totalColumns; //32.266666666666666666666666666667% at 3 columns

.container {
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  width: 960px;
  @include bp-3-columns-without-margin {
    width: 100%;
    padding: 0 20px;
  }
  @include bp-2-columns {
    width: 100%;
    padding: 0 10px;
  }
  @include bp-1-column {
    min-width: 300px;
    width: 100%;
    padding: 0 5px;
  }
}
.row, 
.column {
    box-sizing: border-box;
}
.row::before,
.row::after {
    content: " ";
    display: table;
}
.row::after {
    clear: both;
}
.column {
    position: relative;
    float: left;
  &.column-1 {
    width: $singleColumnWidth;
  }
  &.column-2 { // TODO: Konsolidieren: column-2 vs. column-1-2
    width: (($singleColumnWidth * 2) + ($gutterWidth * (2 - 1)));
  }
  &.column-3 {
    width: (($singleColumnWidth * 3) + ($gutterWidth * ( 3 - 1)));
  }
  &.column-1-2 {
    width: (($singleColumnWidth * 2) + ($gutterWidth * ( 2 - 1)));
  }
  &.column-1-3 {
    width: (($singleColumnWidth * 3) + ($gutterWidth * ( 3 - 1)));
  }
  &.offset-1 {
    margin-left: $singleColumnWidth + $gutterWidth;
  }
  &.offset-2 {
    margin-left: ($singleColumnWidth * 2) + ($gutterWidth * 2);
  }
  @include bp-2-columns {
    &.column-1,
    &.column-2,
    &.column-3,
    &.column-1-2 {
      width: auto;
      float: none;
      clear: both;
    }
    &.offset-2 {
      margin-left: $singleColumnWidth + $gutterWidth;
    }
  }
  @include bp-1-column {
    &.column-1,
    &.column-2,
    &.column-3,
    &.column-1-2 {
      width: auto;
      float: none;
      clear: both;
    }
    &.offset-1,
    &.offset-2 {
      margin-left: 0;
    }
  }
}
.column + .column {
    margin-left: $gutterWidth * 1%;
    @include bp-2-columns {
      margin-left: 0;
    }
    @include bp-1-column {
      margin-left: 0;
    }
}

// Grid for item (either on a wish list or on a search result list)
.item {
  box-sizing: border-box;
  width: 100%;
  &.two-columns {
    width: 96 / 2 * 1%;
    margin-left: 1%;
    margin-right: 1%;
    float: left;
  }
  &.three-columns {
    width: 94 / 3 * 1%;
    margin-left: 1%;
    margin-right: 1%;
    height: 346px;
    float: left;
    @include bp-2-columns {
      width: 96 / 2 * 1%;
      height: 324px;
    }
    @include bp-1-column {
      width: 98%;
      height: 262px;
    }
    .item-image-frame {
      margin: 10px auto;
      float: none;
      position: relative;
      // @include bp-2-columns {
        // height: 140px; // 140px image height + 10px margin-top and -bottom each for image
        // max-width: 140px; // To have a square image
      // }
      // @include bp-1-column {
        // height: 100px;// 100px image height + 10px margin-top and -bottom each for image
        // max-width: 100px; // To have a square image
      // }
    }
    .item-details {
      float: none;
      padding: 0;
      height: auto;
      min-height: none;
      .item-label {
        color: #888888;
      }
      .item-header {
        height: 25px;
        padding: 0 (0.5 * $fontSize);
        text-overflow: ellipsis;
        h4 {
          font-weight: bold;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          a {
            color: #000000;
          }
        }
      }
      .item-description {
        height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        text-overflow: ellipsis;
        padding: 0 (0.5 * $fontSize);
        @include bp-2-columns {
          height: 32px;
        }
        @include bp-1-column {
          height: 30px;
        }
      }
      .item-price {
        height: 20px;
        overflow: hidden;
        text-align: right;
        vertical-align: middle;
        font-weight: bold;
        padding: 0 (0.5 * $fontSize);
      }
      .item-links {
        padding: 0 (0.5 * $fontSize);
        // Used to vertically align shop logos in search results
        span {
          vertical-align: middle;
        }
        img {
          max-height: 31px;
          vertical-align: middle;
        }
      }
      .show-item-link {
        background: #000000;
        height: 40px;
        text-align: center;
        cursor: pointer;
        margin-top: 14px;
        float: none;
        a {
          color: #ffffff;
          vertical-align: middle;
          padding: 6px 20px;
          @include bp-2-columns {
            padding: 4px 20px;
          }
          @include bp-1-column {
            padding: 3px 20px;
          }
        }
      }
    }
    .show-item-link {
      background: #000000;
      height: 40px;
      text-align: center;
      cursor: pointer;
      margin-top: 14px;
      display: none;
      position: absolute;
      bottom: 10px;
      right: 10px;
      @include bp-1-column {
        display: block;
      }
      a {
        color: #ffffff;
        vertical-align: middle;
        padding: 6px 20px;
        @include bp-2-columns {
          padding: 4px 20px;
        }
        @include bp-1-column {
          padding: 3px 20px;
        }
      }
    }
  }
  &.four-columns { // Used for recommendatinos
    width: 92 / 4 * 1%;
    margin-left: 1%;
    margin-right: 1%;
    float: left;
    @include bp-2-columns {
      width: 96 / 2 * 1%;
    }
    @include bp-1-column {
      width: 96 / 2 * 1%;
    }
  }
}